<template>
  <div class="country-icon">
    <img loading="lazy" :src="imagePath" alt="#" />
  </div>
</template>

<script>
// TODO On project end, prop id will be removed
export default {
  name: "CountryIcon",
  props: {
    imagePath: { type: [String, null], default: null }
  }
};
</script>

<style lang="scss">
.country-icon {
  font-size: $country-icon-base-font;
  width: 1em;
  height: 1em;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: initial;
    display: block;
  }
}
</style>
