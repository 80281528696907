
import { defineComponent, PropType } from "vue";
import {
  badgeSizes,
  badgeTypes,
  TBadgeSize,
  TBadgeType
} from "@/components/ui/AppBadge/interfaces";

export default defineComponent({
  name: "AppBadge",
  props: {
    size: {
      type: String as PropType<TBadgeSize>,
      default: badgeSizes.sm
    },

    type: {
      type: String as PropType<TBadgeType>,
      default: badgeTypes.base
    },
    rounded: { type: Boolean, default: true }
  }
});
