<template>
  <div
    class="country d-flex"
    :class="{
      'align-items-center': !description
    }"
  >
    <CountryIcon v-if="imagePath" :image-path="imagePath" />

    <div class="country-text-wrapper">
      <span class="country-name d-block">{{ name }}</span>
      <span v-if="description" class="secondary-text">{{ description }}</span>
    </div>
  </div>
</template>

<script>
// TODO On project end, prop id will be removed

import CountryIcon from "@/components/ui/Country/CountryIcon";
export default {
  name: "Country",
  components: { CountryIcon },
  props: {
    imagePath: {
      type: [String, null],
      default: null
    },
    id: {
      type: [String, Number, null],
      default: null
    },
    name: {
      type: String,
      required: true
    },
    description: { type: String, required: false }
  }
};
</script>

<style lang="scss">
.country {
  line-height: 1;
  &-name {
    white-space: nowrap !important;
  }
}
.country-icon + .country-name {
  margin-left: 0.714rem;
}

.country-icon {
  border-radius: 50%;
  overflow: hidden;
}
.country-icon + .country-text-wrapper {
  margin-left: 10px;
}
</style>
