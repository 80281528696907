export enum badgeTypes {
  base = "base",
  basePrimary = "base-primary",
  lightPrimary = "light-primary",
  secondary = "secondary",
  lightSecondary = "light-secondary",
  danger = "danger",
  primary = "primary",
  success = "success"
}

export enum badgeSizes {
  sm = "sm",
  md = "md"
}

export type TBadgeType = typeof badgeTypes[keyof typeof badgeTypes];
export type TBadgeSize = typeof badgeSizes[keyof typeof badgeSizes];
