<template>
  <div class="device-info">
    <h4 class="device-info-name">{{ name }}</h4>
    <p v-if="os" class="device-info-os">{{ os }}</p>
  </div>
</template>

<script>
export default {
  name: "DeviceInfo",
  props: {
    name: {
      type: String,
      required: true
    },
    os: {
      type: String,
      required: false
    }
  }
};
</script>

<style scoped lang="scss">
.device-info {
  font-size: 1rem;
  font-weight: 500;
  &-name {
    font-size: 0.982em;
    margin-bottom: 0;
  }
  &-os {
    margin-bottom: 0;
    font-size: 0.714em;
    color: $grey-100;
  }
}
</style>
